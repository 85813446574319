import assert from 'browser-assert';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import Plate from 'components/UI/Plate/Plate';
import Button from 'components/UI/Button/Button';
import { TextInput } from 'components/UI/TextInput/TextInput';
import CheckBox from 'components/UI/CheckBox/CheckBox.jsx';

import messageBoxStore from 'MessageBox/MessageBoxStore';
import popupStore from 'components/Popup/PopupStore.js';

import { ApiError } from '../../client.js';
import { getCookie, setCookie } from 'utils/cookie.js';

import AppStore from 'AppStore.js';

import { SponsorId } from 'domain/id.js';

import { EVENTS, metricsEvent } from 'metrics';
import client from 'client';

import './SignUp.scss';

export const SignUp = observer(({ sponsorId }) => {
  assert(!sponsorId || (sponsorId instanceof SponsorId));

  const navigate = useNavigate(); 
  const redirectUrl = getCookie('redirect_url');

  const [isBusy, setIsBusy] = useState(false);

  const [login, setLogin] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  async function onSubmit() {
    if (isBusy)
      return;

    if (!login)
      return messageBoxStore.showError('Введите email.');

    if (!password1)
      return messageBoxStore.showError('Введите пароль.');
    if (password2 !== password1)
      return messageBoxStore.showError('Пароли не совпадают.');

    let token = '';

    setIsBusy(true);
    try {
      token = await client.signup(login, password1, sponsorId?.valueOf());
      metricsEvent(EVENTS.SIGNUP_SUBMIT);
    } catch (e) {
      if (e instanceof ApiError) {
        messageBoxStore.showError(e.message);
        return;
      }
      throw e;
    } finally {
      setIsBusy(false);
    }

    AppStore.setRightNowRegistered();
    AppStore.setToken(token);

    await AppStore.updateAcceptAgreements();

    if (redirectUrl) {
      setCookie('redirect_url', '', { expires: -1 });
      navigate(redirectUrl);
    } else {
      navigate('/');
    }
  }

  const handleCheckboxClick = () => {
    if (AppStore.agreedWithRules) {
      AppStore.setAgreedWithRules(false);
    } else {
      popupStore.open('accept-rules');
    }
  };

  return (
    <div className='signup'>
      <Helmet>
        <title>Регистрация в сервисе — Boostclick</title>
        <meta name="description" content="Зайти в личный кабинет для регистрации в сервисе Boostclick" />
        <meta name="robots" content="noindex" />
      </Helmet>

      <form className="signup__form" onSubmit={e => { e.preventDefault(); onSubmit() }}>
        <Plate className='signup__plate'>
          <h1 className='signup__title'>Регистрация</h1>

          <TextInput classes={["signup__input"]} placeholder='Email' type='email' onChange={e => setLogin(e.target.value)} />
          <TextInput classes={["signup__input"]} placeholder='Пароль' type='password' onChange={e => setPassword1(e.target.value)} />
          <TextInput classes={["signup__input"]} placeholder='Повторите пароль' type='password' onChange={e => setPassword2(e.target.value)} />

          <CheckBox
            title="Согласен(-a) с правилами и условиями использования сайта"
            classes={["signup__check"]}
            checked={AppStore.agreedWithRules}
            onClick={handleCheckboxClick}
          />

          <Button title='Зарегистрироваться' disabled={!AppStore.agreedWithRules} />
        </Plate>
      </form>
    </div>
  );
});
