import { observer } from "mobx-react";

import Button from "components/UI/Button/Button";
import AmountItem from "pages/Referral/components/UI/AmountItem/AmountItem";

import "./AmountSection.scss";

const AmountSection = observer(({ store, onWithdraw }) => {

  const amountItems = [
    { label: "В процессе вывода", value: store.bonusesWithdrawing, faq: "Перевод средств может занять до 24 часов, но обычно происходит быстрее" },
    { label: "Выведено средств", value: store.bonusesWithdrawn, faq: "Общая сумма всех выведенных средств" },
    { label: "Всего заработано", value: store.bonusesEarned, faq: "Общая сумма заработка за все время, включая выведенные и оставшиеся на балансе средства" },
  ];

  return (
    <div className="withdrawing__amount-section">
      <div className="withdrawing__amount-section_balance">
        <div>
          <p className="withdrawing__amount-section_balance-text">Текущий баланс</p>
          <p className="withdrawing__amount-section_balance-count">{store.bonusesAvailable?.toLocaleString() ?? ''}</p>
        </div>
        <Button title="Вывести средства" onClick={onWithdraw} disabled={store.bonusesAvailable === undefined} />
      </div>
      <div className="withdrawing__amount-section_total">
        {amountItems.map((item, index) => <AmountItem key={index} {...item} />)}
      </div>
    </div>
  );
});

export default AmountSection;
