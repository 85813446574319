import { observer } from "mobx-react";

import "./HiddenContent.scss";

const HiddenContent = observer(({ content }) => {
  return (
    <div className="hidden-content">{content.map((item, index) => <div key={index}>{item}</div>)}</div>
  );
});

export default HiddenContent;
