import { useEffect, useState } from 'react';

import ProjectsPlate from '../components/ProjectsPlate/ProjectsPlate';
import ProjectsDraftsRow from './ProjectsDraftsRow/ProjectsDraftsRow';
import { NO_PROJECTS_COMMENT_FOR_DRAFTS } from '../../Projects/ProjectsConstants';

import './ProjectsDrafts.scss';

export default function ProjectsDrafts({ projects }) {

  const comment = (!projects || projects.length) ? '' : NO_PROJECTS_COMMENT_FOR_DRAFTS;
  const [expandedTypes, setExpandedTypes] = useState([]);

  const toggleType = (type) => {
    if (expandedTypes.includes(type)) {
      setExpandedTypes(expandedTypes.filter((t) => t !== type));
    } else {
      setExpandedTypes([...expandedTypes, type]);
    }
  };

  const groupedProjects = projects?.reduce((acc, project) => {
    const type = project.type;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(project);
    return acc;
  }, {});

  useEffect(() => {
    const projectTypes = new Set(projects?.map(project => project.type));
    setExpandedTypes(Array.from(projectTypes));
  }, [projects]);

  return (
    <div className='projects-drafts'>
      {projects?.length ? (
        <>
          {Object.entries(groupedProjects).map(([type, typeProjects]) => {
            const totalSum = typeProjects.reduce((sum, project) => sum + (project.price || 0), 0);

              return (
                <div key={type}>
                  <div className="projects-drafts__row" onClick={() => toggleType(type)}>
                    <div className="projects-drafts__row-total-project">
                      <div className={`projects-drafts__row-accordion-icon ${expandedTypes.includes(type) ? "expanded" : ""}`} />
                      <h3 className="projects-drafts__row-title">Проекты {type === "AVITO" ? "ОБЪЯВЛЕНИЯ" : type} ({typeProjects.length} шт.)</h3>
                    </div>
                    <h3 className={`projects-drafts__row-total-sum ${expandedTypes.includes(type) ? "other-color": ""}`}>Общая сумма: {totalSum.toLocaleString()} ₽</h3>
                  </div>
                  <div className={`projects-archive__row-reports ${expandedTypes.includes(type) ? "expanded" : ""}`}>
                    {typeProjects.map((project, i) => <ProjectsDraftsRow key={i} project={project} />)}
                  </div>
                </div>
              );
          })}
        </>
        ) : (
          <ProjectsPlate title="Тут пусто" comment={comment} />
        )}
    </div>
  );
}
