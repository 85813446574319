import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { App } from './App.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    {process.env.REACT_APP_YM_ID &&
      <Helmet>
        <script type='text/javascript'>
          {
          `
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();
          for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
          k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
          
          ym(${process.env.REACT_APP_YM_ID}, "init", {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
          });
          `
          }
        </script>
      </Helmet>
    }

    {process.env.REACT_APP_BOTFAQTOR_ID &&
      <Helmet>
        <script type='text/javascript'>
          {`window._ab_id_=${process.env.REACT_APP_BOTFAQTOR_ID}`}
        </script>
        <script src="https://cdn.botfaqtor.ru/one.js"></script>
      </Helmet>
    }

    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);
