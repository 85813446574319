import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import Button from "components/UI/Button/Button";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import { DropDownMenu } from "components/DropDownMenu/DropDownMenu";
import { DropdownLinks } from "components/DropDownLinks/DropDownLinks";

import servicesIcon from "assets/images/header/servicesIcon.svg";
import closeIcon from "assets/images/header/closeIconServices.svg";
import projectsIcon from "assets/images/header/projectsIcon.svg";
import blogIcon from "assets/images/header/blogIcon.svg";
import faqIcon from "assets/images/header/faqIcon.svg";
import loginIcon from "assets/images/header/login.svg";
import notifyOn from "assets/images/header/bell-on.svg";
import notifyOff from "assets/images/header/bell-off.svg";

import AppStore from "AppStore";

import { EVENTS, metricsEvent } from "metrics";

import "./IsDesktopHeader.scss";

export const IsDesktopHeader = observer(
  ({
    appStore,
    dropStore,
    onlyLogo,
    popularServices,
    locationPathname,
    saveRedirectUrl,
    pathToFaq,
    isDropdownListServices,
    toggleDropdownListServices,
    onCloseDropdownListServices,
    toggleTelegramNotifications
  }) => {

    const isAuth = AppStore.isAuth;

    const buttons = [
      {
        title: "Мои проекты",
        link: AppStore.isAuth ? "/projects" : "/signin",
        icon: projectsIcon,
      },
      {
        title: "Блог",
        link: "/blog",
        icon: blogIcon,
      },
    ];

    return (
      <div className={`header__inner ${onlyLogo ? "only-logo" : ""}`}>
        <Link to='/' className={`header__logo ${onlyLogo ? "only-logo" : ""}`} />

        {!onlyLogo && (
          <>
            <div className="header__dropdown-container">
              <button
                className={
                  "header__link" + (isDropdownListServices ? " active" : "")
                }
                onClick={toggleDropdownListServices}
              >
                <div
                  className="header__link-icon"
                  style={{
                    backgroundImage: `url(${isDropdownListServices ? closeIcon : servicesIcon
                      })`,
                    transition: "all 0.4s",
                  }}
                />
                Услуги
              </button>
              <DropdownLinks
                isOpen={isDropdownListServices}
                onClose={onCloseDropdownListServices}
                links={popularServices}
              />
            </div>
            {buttons.map((i, ind) => (
              <Link
                to={i.link}
                key={ind}
                className={
                  "header__link" +
                  (locationPathname === i.link ? " active" : "")
                }
                onClick={i.onClick}
              >
                <div
                  className="header__link-icon"
                  style={{ backgroundImage: `url(${i.icon})` }}
                />
                {i.title}
              </Link>
            ))}
            <a href={pathToFaq} className="header__link">
              <div
                className="header__link-icon"
                style={{ backgroundImage: `url(${faqIcon})` }}
              />
              FAQ
            </a>

            <div className="header__auth-container">
              <Link
                to={process.env.REACT_APP_SUPPORT_ADDRESS}
                target="_blank"
                onClick={() => { metricsEvent(EVENTS.TELEGRAM_HELP) }}
              >
                <div className="header__support">Помощь</div>
              </Link>
              {isAuth &&
                <ButtonWithIcon
                  title="Уведомления"
                  className="header__notifications"
                  icon={AppStore.telegramNotify ? notifyOn : notifyOff}
                  ariaLabel="Уведомления в телеграм-боте"
                  onClick={toggleTelegramNotifications}
                />
              }
              {isAuth ? (
                <div className="header__user-container">
                  <div className="header__user" onClick={dropStore.open}>
                    {appStore.userEmail === undefined ? (
                      <div className="skeleton" />
                    ) : (
                      appStore.userEmail
                    )}
                  </div>
                  <DropDownMenu />
                </div>
              ) : (
                <>
                  <Link to="/signup">
                    <Button
                      title="Регистрация"
                      className="header__auth-signup"
                      onClick={() => {
                        metricsEvent(EVENTS.SIGNUP_OPEN);
                        appStore.setIsFormModeLogin(false);
                        saveRedirectUrl();
                      }}
                    />
                  </Link>
                  <Link to="/signin">
                    <ButtonWithIcon
                      title="Войти"
                      icon={loginIcon}
                      className="header__auth-signin"
                      onClick={() => {
                        appStore.setIsFormModeLogin(true);
                        saveRedirectUrl();
                      }}
                    />
                  </Link>
                </>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
);
