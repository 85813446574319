import { useEffect, useState } from "react";

import tshirt from "assets/images/dashboard/intro/illustration/category-tshirt.svg";
import build from "assets/images/dashboard/intro/illustration/category-build.svg";
import services from "assets/images/dashboard/intro/illustration/category-services.svg";
import phone from "assets/images/dashboard/intro/illustration/category-phone.svg";
import pet from "assets/images/dashboard/intro/illustration/category-pet.svg";

import "./IntroIllustration.scss";

const IntroIllustration = ({ marketPath }) => {
  const isViewsPage = marketPath.includes('views');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [cursorState, setCursorState] = useState("start");
  const [heartState, setHeartState] = useState("default");
  const [isTransitioning, setIsTransitioning] = useState(true);

  const originalSlides = [
    { type: "tshirt", image: tshirt },
    { type: "build", image: build },
    { type: "services", image: services },
    { type: "phone", image: phone },
    { type: "pet", image: pet },
  ];
  const slides = [...originalSlides, originalSlides[0]];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => {
        if (prev === slides.length - 1) {
          setTimeout(() => {
            setIsTransitioning(false);
            setCurrentSlide(0);
            requestAnimationFrame(() => {
              requestAnimationFrame(() => {
                setIsTransitioning(true);
              });
            });
          }, 2000);
          return prev;
        }
        return prev + 1;
      });
    }, 3000);
    return () => clearInterval(timer);
  }, [slides.length]);

  useEffect(() => {
    const sequence = [
      () => { setCursorState("heart"); },
      () => { setHeartState("hovered"); },
      () => { setHeartState("clicked"); },
      () => { setHeartState("done"); setCursorState("done"); },
    ];

    const timings = [2000, 2400, 2800, 3200];
    const timers = timings.map((timing, index) => { return setTimeout(sequence[index], timing); });

    return () => { timers.forEach((timer) => clearTimeout(timer)); };
  }, []);

  const getCursorClassName = () => {
    const baseClass = "intro-illustration__cursor";
    const cursorStates = {
      heart: "cursor-heart",
      done: "cursor-done",
      start: "cursor-start"
    };
    
    return `${baseClass} ${cursorStates[cursorState] || cursorStates.start}`;
  };

  return (
    <div className={`intro-illustration ${isViewsPage ? 'other-background' : ''}`}>
      <div className="intro-illustration__slider">
        <div className={`intro-illustration__slides ${isTransitioning ? "with-transition" : ""}`} style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          {slides.map((slide, index) => (
            <div key={index} className="intro-illustration__slides-item">
              <div style={{ background: `url(${slide.image}) center/contain no-repeat` }} />
            </div>
          ))}
        </div>
      </div>

      <div className="intro-illustration__heart">
        <div className={`intro-illustration__heart-${heartState}`} />
      </div>

      <div className={getCursorClassName()} />

      {isViewsPage && (
        <div className="intro-illustration__overlay">
          <div className="intro-illustration__overlay-icon" />
        </div>
      )}
    </div>
  );
};

export default IntroIllustration;
