import { observer } from "mobx-react";

import PropertyRow from "pages/Referral/components/UI/PropertyRow/PropertyRow";
import EmptySection from "pages/Referral/components/UI/EmptySection/EmptySection";
import ValueRow from "pages/Referral/components/UI/ValueRow/ValueRow";

import AppStore from "AppStore";

import "./ReferralHistorySection.scss";

export const ReferralHistorySection = observer(({
  data,
  isMobile,
  type,
  emptyTitle,
  emptyDescription,
  propertyRowConfig = {},
}) => {

  const defaultPropertyRowConfig = {
    userText: "Пользователь",
    dateText: "Дата регистрации",
    amountText: "Сумма",
    ...(type === "withdrawing" && { dateText: "Дата и время", statusText: "Статус" }),
  };

  const finalPropertyRowConfig = {
    ...defaultPropertyRowConfig,
    ...propertyRowConfig,
  };

  return (
    <>
      {!isMobile && <PropertyRow {...finalPropertyRowConfig} type={type} />}
      {data && (
        !data.length ? (
          <EmptySection title={emptyTitle} text={emptyDescription} />
        ) : (
          data.map((item, index) =>
            <ValueRow
              key={index}
              {...item}
              isMobile={isMobile}
              type={type}
              login={item.referral ? item.referral.emailHidden : AppStore.userEmail}
              accountType={item.referral ? (item.referral.base ? 'Базовый' : '') : 'Базовый'}
              id={item.referral ? item.referral.id : AppStore.userId}
              amount={item.amount || item.earned}
              date={item.datetime || item.referral.registerDatetime}
              status={(() => {
                if (!item.type)
                  return null;
                if (item.type === 'INCOME')
                  return 'Начислено';
                if (item.type === 'WITHDRAWAL_TO_BALANCE')
                  return 'Выведено на счет';
                if (item.type === 'WITHDRAWAL_TO_CARD')
                  return item.status === 'finished' ? 'Выведено на карту' : 'В процессе вывода на карту';
                return '';
              })()}
            />
          )
        )
      )}
    </>
  );
});
