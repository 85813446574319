import Faqed from "components/UI/Faqed/Faqed";

import { REFERRAL_TITLE, REFERRAL_DISCLAIMER } from "pages/Referral/referralConstants";

import "./ReferralInformation.scss";

const ReferralInformation = () => {
  return (
    <section className="referral__information">
      <h1 className="referral__information-title">{REFERRAL_TITLE}</h1>
      <h2 className="referral__information-text">
        Зарабатывайте
        <br />
        <span>до 10% <Faqed text={REFERRAL_DISCLAIMER}></Faqed></span>
        от суммы пополнения кабинета
      </h2>
    </section>
  );
};

export default ReferralInformation;
