import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import messageBoxStore from 'MessageBox/MessageBoxStore';
import popupStore from 'components/Popup/PopupStore';

import client, { ClientError } from 'client';

export const WithdrawalMethods = {
  ACCOUNT: 'account',
  CARD: 'card',
};

const DEFAULT_APP_HOST_ADDRESS = 'https://boostclick.ru';

/**
 * @param {number} userId
 * @returns {string}
 */
function buildReferralLink(userId) {
  const host = process.env.REACT_APP_HOST_ADDRESS ?? DEFAULT_APP_HOST_ADDRESS;
  const url = new URL(`/invite/${userId}`, host);
  return url.toString();
}

/**
 * @typedef {object} Pagination
 * @property {number} pages
 * @property {number} curr
 */

/** Стор для кабинета рефки. */
class ReferralStore {

  /** @type {?string} */
  referralLink = undefined;

  /** @type {?number} */
  bonusesAvailable = undefined;
  /** @type {?number} */
  bonusesEarned = undefined;
  /** @type {?number} */
  bonusesWithdrawing = undefined;
  /** @type {?number} */
  bonusesWithdrawn = undefined;

  bonusesHistory = undefined;
  /** @type {?Pagination} */
  bonusesHistoryPagination = undefined;
  bonusesStats = undefined;
  /** @type {?Pagination} */
  bonusesStatsPagination = undefined;

  /** @type {string} */
  withdrawalMethod = WithdrawalMethods.ACCOUNT;

  /** @type {?number} */
  amountToWithdraw = undefined;

  /** @type {boolean} */
  agreedWithOffer = false;

  /** @type {boolean} */
  doWithdrawalRunning = false;

  startDate = null;
  endDate = null;

  #clearBonuses() {
    this.bonusesAvailable = undefined;
    this.bonusesEarned = undefined;
    this.bonusesWithdrawing = undefined;
    this.bonusesWithdrawn = undefined;
  }

  /** @param {import('client').BonusesInfo} info */
  #parseBonuses(info) {
    this.bonusesAvailable = info.available;
    this.bonusesEarned = info.earned;
    this.bonusesWithdrawing = info.withdrawing;
    this.bonusesWithdrawn = info.withdrawn;
  }

  constructor() {
    makeObservable(this, {
      fetchData: action,
      referralLink: observable,

      bonusesAvailable: observable,
      bonusesEarned: observable,
      bonusesWithdrawing: observable,
      bonusesWithdrawn: observable,

      bonusesHistory: observable,
      bonusesHistoryPagination: observable,
      bonusesStats: observable,
      bonusesStatsPagination: observable,

      fetchBonusesHistory: action,
      fetchBonusesStats: action,

      withdrawalMethod: observable,
      minWithdrawalAmount: computed,
      setWithdrawalMethod: action,

      amountToWithdraw: observable,
      amountToReceive: computed,
      setAmountToWithdraw: action,

      agreedWithOffer: observable,
      setAgreedWithOffer: action,

      resetWithdrawalParams: action,
      canWithdraw: computed,
      doWithdrawal: action,
      doWithdrawalRunning: observable,

      startDate: observable,
      endDate: observable,
      setStartDate: action,
      setEndDate: action,
    });
  }

  /** Загрузка всех данных, нужных для отрисовки кабинета рефки. */
  fetchData() {
    this.#fetchProfile();
    this.#fetchBonusesInfo();
    this.#fetchBonusesHistory(1);
    this.#fetchBonusesStats(1);
  }

  fetchBonusesHistory(page, clear = true) {
    this.#fetchBonusesHistory(page, clear);
  }

  fetchBonusesStats(page, clear = true) {
    this.#fetchBonusesStats(page, clear);
  }

  #fetchingProfile = false;
  /** Загрузка данных профиля для получения идентификатора пользователя. */
  async #fetchProfile() {
    if (this.#fetchingProfile)
      return;
    this.#fetchingProfile = true;
    this.referralLink = undefined;
    try {
      const profile = await client.getProfile();
      const link = buildReferralLink(profile.id);
      runInAction(() => {
        this.referralLink = link;
      });
    } catch (e) {
      if (e instanceof ClientError)
        messageBoxStore.showError(e.message);
      else
        throw e;
    } finally {
      this.#fetchingProfile = false;
    }
  }

  #fetchingBonusesInfo = false;
  /** Загрузка данных о бонусах. */
  async #fetchBonusesInfo() {
    if (this.#fetchingBonusesInfo)
      return;
    this.#fetchingBonusesInfo = true;

    this.#clearBonuses();
    try {
      const info = await client.getBonusesInfo();
      runInAction(() => {
        this.#parseBonuses(info);
      });
    } catch (e) {
      if (e instanceof ClientError)
        messageBoxStore.showError(e.message);
      else
        throw e;
    } finally {
      this.#fetchingBonusesInfo = false;
    }
  }

  #fetchingBonusesHistory = false;
  /** Загрузка данных о бонусах. */
  async #fetchBonusesHistory(page, clear = true) {
    if (this.#fetchingBonusesHistory)
      return;
    this.#fetchingBonusesHistory = true;

    try {
      const history = await client.getBonusesHistory();
      runInAction(() => {
        if (clear)
          this.bonusesHistory = history;
        else
          this.bonusesHistory.push(...history);
//      this.bonusesHistoryPagination = { pages: 10, curr: page };
      });
    } catch (e) {
      if (e instanceof ClientError)
        messageBoxStore.showError(e.message);
      else
        throw e;
    } finally {
      this.#fetchingBonusesHistory = false;
    }
  }

  #fetchingBonusesStats = false;
  /** Загрузка данных о бонусах. */
  async #fetchBonusesStats(page, clear = true) {
    if (this.#fetchingBonusesStats)
      return;
    this.#fetchingBonusesStats = true;

    try {
      const stats = await client.getBonusesStats();
      runInAction(() => {
        if (clear)
          this.bonusesStats = stats;
        else
          this.bonusesStats.push(...stats);
//      this.bonusesStatsPagination = { pages: 10, curr: page };
      });
    } catch (e) {
      if (e instanceof ClientError)
        messageBoxStore.showError(e.message);
      else
        throw e;
    } finally {
      this.#fetchingBonusesStats = false;
    }
  }  

  /** @param {string} method */
  setWithdrawalMethod(method) {
    this.withdrawalMethod = method;
  }

  /** @param {number|string} */
  setAmountToWithdraw(value) {
    if (value === undefined)
      return;

    if (value === null) {
      this.amountToWithdraw = undefined;
      return;
    }

    if (typeof value === 'string') {
      if (!value.trim()) {
        this.amountToWithdraw = undefined;
        return;
      }
      value = Number(value);
    }
    if (typeof value !== 'number')
      return;

    if (!Number.isFinite(value))
      return;
    if (value < 0 || value > 1000000)
      return;
    if (!Number.isInteger(value) && !Number.isInteger(value * 100))
      return;

    this.amountToWithdraw = value;
  }

  /** @returns {?number} */
  get amountToReceive() {
    if (!this.canWithdraw)
      return undefined;
    if (!this.amountToWithdraw)
      return 0;
    if (this.withdrawalMethod === WithdrawalMethods.ACCOUNT)
      return this.amountToWithdraw;
    if (this.withdrawalMethod === WithdrawalMethods.CARD)
      return Math.floor((this.amountToWithdraw * 0.94) * 100) / 100;
    return 0;
  }

  /** @returns {boolean} */
  get canWithdraw() {
    if (this.withdrawalMethod === WithdrawalMethods.CARD) {
      if (!this.agreedWithOffer)
        return false;
    }

    const amount = this.amountToWithdraw;
    return amount && this.bonusesAvailable
      && amount >= this.minWithdrawalAmount
      && amount <= this.bonusesAvailable;
  }

  get minWithdrawalAmount() {
    switch (this.withdrawalMethod) {
      case WithdrawalMethods.ACCOUNT:
        return 100;
      case WithdrawalMethods.CARD:
        return 50000;
      default:
        return 0;
    }
  }

  setAgreedWithOffer(value) {
    this.agreedWithOffer = !!value;
  }

  resetWithdrawalParams() {
    this.agreedWithOffer = false;

    const bonuses = this.bonusesAvailable;
    if (bonuses && bonuses >= this.minWithdrawalAmount)
      this.amountToWithdraw = bonuses;
    else
      this.amountToWithdraw = undefined;
  }

  async doWithdrawal() {
    if (this.doWithdrawalRunning)
      return;
    if (!this.canWithdraw)
      return;
  
    let func;
    if (this.withdrawalMethod === WithdrawalMethods.ACCOUNT)
      func = client.moveBonusesToBalance;
    else if (this.withdrawalMethod === WithdrawalMethods.CARD)
      func = client.moveBonusesToCard;
    else
      return;

    this.doWithdrawalRunning = true;

    try {
      const info = await func.call(client, this.amountToWithdraw);
      this.#fetchBonusesHistory();
      runInAction(() => {
        this.#parseBonuses(info);
      });
      popupStore.close();
    } catch (e) {
      if (e instanceof ClientError)
        messageBoxStore.showError(e.message);
      else
        throw e;
    } finally {
      runInAction(() => {
        this.doWithdrawalRunning = false;
      });
    }
  }

  setStartDate(date) {
    this.startDate = date;
  }

  setEndDate(date) {
    this.endDate = date;
  }
}

const referralStore = new ReferralStore();
export default referralStore;
