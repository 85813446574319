import create from "assets/images/dashboard/howitworks/step1-icon.svg";
import search from "assets/images/dashboard/howitworks/step2-icon.svg";
import report from "assets/images/dashboard/howitworks/step3-icon.svg";
import step1 from "assets/images/dashboard/howitworks/step1.svg";
import step2 from "assets/images/dashboard/howitworks/step2.svg";
import step3 from "assets/images/dashboard/howitworks/step3.svg";

export const HOW_IT_WORKS_TITLE = "🤔 Как работает биржа заданий boostсlick.ru?";
export const STEP1_TITLE_CREATE_TASK = "Вы создаете задачу";
export const STEP2_TITLE_LOOKING_PERFORMER = "Сервис ищет исполнителя";
export const STEP2_DESCRIPTION_LOOKING_PERFORMER  = "Система автоматически формирует сценарии и ищет под каждый исполнителя";
export const STEP3_TITLE_CHECK_PAY_REPORT = "Вы проверяете отчет и оплачиваете работу";
export const STEP3_DESCRIPTION_CHECK_PAY_REPORT = "Вы принимаете отчет или отклоняете, если он заполнен неверно. Платите только за готовое задание";

export { create, search, report, step1, step2, step3 };
