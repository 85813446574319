import React, { useState, useEffect, useRef, useCallback } from "react";

import "./IntroPoints.scss";

const IntroPoints = ({ stepLength }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef(null);
  const intervalRef = useRef(null);

  const startAutoScroll = useCallback(() => {
    const container = containerRef.current;
    const stepElement = container?.querySelector(".intro-points-animation__track-step");
    
    if (!container || !stepElement) return;

    const stepWidth = stepElement.offsetWidth;

    intervalRef.current = setInterval(() => {
      setActiveIndex(prev => {
        const newIndex = (prev + 1) % stepLength.length;
        container.scrollTo({
          left: newIndex * stepWidth,
          behavior: "smooth"
        });
        return newIndex;
      });
    }, 2000);
  }, [stepLength.length]);

  const stopAutoScroll = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(startAutoScroll, 2000);
    const container = containerRef.current;

    const handleInterrupt = () => stopAutoScroll();

    if (container) {
      container.addEventListener("touchstart", handleInterrupt);
      container.addEventListener("mousedown", handleInterrupt);
      container.addEventListener("wheel", handleInterrupt);
    }

    return () => {
      clearTimeout(timer);
      stopAutoScroll();
      if (container) {
        container.removeEventListener("touchstart", handleInterrupt);
        container.removeEventListener("mousedown", handleInterrupt);
        container.removeEventListener("wheel", handleInterrupt);
      }
    };
  }, [startAutoScroll, stopAutoScroll]);

  return (
    <div ref={containerRef} className="intro-points-animation">
      <div className="intro-points-animation__track">
        {stepLength.map((advice, index) => {
          const isActive = index === activeIndex;
          const isPassed = index <= activeIndex;

          return (
            <div key={index} className={`intro-points-animation__track-step ${isActive ? 'active' : ''} ${isPassed ? 'passed' : ''}`}>
              <div className="intro-points-animation__icons">
                <div className={`intro-points-animation__icons-plus ${isActive ? 'active' : ''} ${isPassed ? 'passed' : ''}`} />
                <hr className={`intro-points-animation__icons-line ${isActive ? 'active' : ''} ${isPassed ? 'passed' : ''}`} />
              </div>
              <div className="intro-points-animation__advice-content">{advice}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IntroPoints;