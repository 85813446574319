import { Fragment } from "react";

import Plate from "components/UI/Plate/Plate";

import "./HowItWorksSteps.scss";

export const HowItWorksSteps = ({ icon, step, title, text, isReferralPage, isThirdStep }) => {
  return (
    <Plate className={`how-it-works-steps ${isReferralPage ? "other-params" : ""} ${isThirdStep ? "third-step" : ""}`}>
      <div className="how-it-works-steps__icon">
        <img src={icon.src} alt={icon.alt} className="how-it-works-steps__icon-img" />
        <img src={step.src} alt={step.alt} className="how-it-works-steps__icon-step" />
      </div>
      <hr className={`how-it-works-steps__icon-border ${isReferralPage ? "other-params" : "" }`} />

      <div className="how-it-works-steps__description">
        <p className="how-it-works-steps__description-title">{title}</p>
        <p className="how-it-works-steps__description-text">
          {Array.isArray(text) 
            ? text.map((item, i) => <Fragment key={i}>{item}</Fragment>)
            : text
          }
        </p>
      </div>
    </Plate>
  );
};

