import { observer } from "mobx-react";

import AmountSection from "./section/AmountSection/AmountSection";
import { ReferralHistorySection } from "../components/ReferralHistorySection/ReferralHistorySection";
import Pagination from "components/UI/Pagination/Pagination";

import popupStore from "components/Popup/PopupStore";

import { REFERRAL_WITHDRAWING_EMPTY_DESCRIPTION, REFERRAL_WITHDRAWING_EMPTY_TITLE } from "../referralConstants";

import { useMatchMedia } from "utils/hooks/use-match-media";

import "./Withdrawing.scss";

const Withdrawing = observer(({ store }) => {
  const { isMobile } = useMatchMedia();

  const pagination = store.bonusesHistoryPagination;

  return (
    <section className="withdrawing">
      <div className="withdrawing__container">
        <h3 className="withdrawing__title">Вывод средств</h3>
        <div className="withdrawing__sum">
          <AmountSection store={store} onWithdraw={() => {
            store.resetWithdrawalParams();
            popupStore.open("referral-withdrawal-modal");
          }} />
        </div>
      </div>

      <div className="withdrawing__container">
        <h3 className="withdrawing__title">История операций</h3>
        <div className={`withdrawing__history`}>
          <ReferralHistorySection
            data={store.bonusesHistory}
            isMobile={isMobile}
            type="withdrawing"
            emptyTitle={REFERRAL_WITHDRAWING_EMPTY_TITLE}
            emptyDescription={REFERRAL_WITHDRAWING_EMPTY_DESCRIPTION}
          />
        </div>
        {pagination && <Pagination
          isMobile={isMobile}
          pages={pagination.pages}
          curr={pagination.curr}
          onPageGoto={(page) => store.fetchBonusesHistory(page)}
          onPageNext={() => store.fetchBonusesHistory(pagination.curr + 1, false)}
        />}
      </div>
    </section>
  );
});

export default Withdrawing;
