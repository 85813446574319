import { observer } from "mobx-react";
import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import Plate from "components/UI/Plate/Plate";
import Button from "components/UI/Button/Button";
import Badges from "components/UI/Badges/Badges.jsx";
import Toggler from "components/UI/Toggler/Toggler";
// import FrozenBalance from "./FrozenBalance/FrozenBalance.jsx";

import AppStore from "AppStore.js";
import dropDownMenuStore from "./DropDownMenuStore";
import popupStore from "components/Popup/PopupStore";
import messageBoxStore from "MessageBox/MessageBoxStore";

import { EVENTS, metricsEvent } from "metrics";

import "./DropDownMenu.scss";

const menuItems = [
  { 
    icon: 'projects',
    text: 'Мои проекты', 
    to: '/projects',
    status: null
  },
  { 
    icon: 'referral',
    text: 'Реферальная программа', 
    to: '/referral',
    status: 'beta'
  },
];

export const DropDownMenu = observer(({ isMobile, headerForDesktop }) => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const dropdownMenuStyles = {
    position: isMobile && !headerForDesktop ? "fixed" : "absolute",
    top: isMobile && !headerForDesktop ? "56px" : "75px",
    bottom: isMobile && !headerForDesktop ? "66px" : "auto",
    right: isMobile && !headerForDesktop ? "0" : "0",
    left: isMobile && !headerForDesktop ? "0" : "auto",
    maxWidth: isMobile && !headerForDesktop ? "none" : "376px",
    opacity: dropDownMenuStore.isActive ? "1" : "0",
    pointerEvents: dropDownMenuStore.isActive ? "all" : "none",
  };

  const handleLogout = () => {
    AppStore.setToken(null);
    AppStore.setHidePaymentNotification(false);
    localStorage.removeItem("hidePaymentNotification");
    navigate("/");
  };

  const handleTelegramNotifications = async () => {
    if (!AppStore.telegramNotify && !AppStore.telegramId) {
      popupStore.open('bot-modal');
      try {
        await AppStore.turnOnNotifications();
      } catch (error) {
        console.error("Ошибка формирования кода:", error);
        messageBoxStore.showError(error || "Произошла ошибка при формировании кода");
      }
    } else {
      try {
        const message = await AppStore.onOffTelegramNotifications(!AppStore.telegramNotify);
        messageBoxStore.showInfo(message);
      } catch (error) {
        console.error("Ошибка при отключении уведомлений:", error);
        messageBoxStore.showError(error.message || "Произошла ошибка при изменении статуса уведомлений");
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        dropDownMenuStore.close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdownmenu" style={dropdownMenuStyles} ref={dropdownRef}>

      {isMobile &&
        <Plate className="dropdownmenu__user">
          <div className="dropdownmenu__user-info">
            <div className="dropdownmenu__user-avatar" {...(AppStore.userEmail ? { 'data-initials': AppStore.userEmail.charAt(0) } : {})} />
            <p className="dropdownmenu__user-name">
              {AppStore.userEmail === undefined ? (
                <div className="dropdownmenu-skeleton" />
              ) : (
                AppStore.userEmail
              )}
            </p>
          </div>
          {/* TODO: settings button */}
        </Plate>
      }

      <Plate className="dropdownmenu__balance">
        <div className="dropdownmenu__balance-total">
          <div className="dropdownmenu__balance-container">
            <div className="dropdownmenu__balance-amount">
              {!isMobile && <p className="dropdownmenu__balance-text">Баланс</p>}
              <p className="dropdownmenu__balance-digit">{dropDownMenuStore.balance.toLocaleString()} ₽</p>
              {isMobile && <p className="dropdownmenu__balance-text">На счету</p>}
            </div>
            {/* {isMobile && <FrozenBalance store={dropDownMenuStore} isMobile={isMobile} />} */}
            {!isMobile &&
              <Button
                title="Пополнить"
                classes={["dropdownmenu__button"]}
                onClick={() => {
                  metricsEvent(EVENTS.DEPOSIT_OPEN);
                  popupStore.open("paymodal");
                }}
              />
            }
          </div>
          {/* {!isMobile && <FrozenBalance store={dropDownMenuStore} />} */}
          {isMobile &&
            <>
              <Button
                title="Пополнить"
                classes={["dropdownmenu__button"]}
                onClick={() => {
                  metricsEvent(EVENTS.DEPOSIT_OPEN);
                  popupStore.open("paymodal");
                }}
              />
              {/* TODO: button payment history */}
            </>
          }
        </div>
        {!isMobile && 
          <div className="dropdownmenu__link">
            {menuItems.map((item, index) => (
              <Link 
                key={index} 
                className="dropdownmenu__link-item" 
                to={item.to} 
                onClick={() => { dropDownMenuStore.close(); window.scrollTo(0, 0); }}
              >
                <p>{item.text}</p>
                {item.status && <Badges status={item.status} />}
              </Link>
            ))}
          </div>
        }
      </Plate>

      <Plate className="dropdownmenu__notifications">
        <div className="dropdownmenu__notifications-container">
          <div className="dropdownmenu__notifications-icon" />
          <p>Уведомления об отчетах в TG</p>
        </div>
        <Toggler isActive={AppStore.telegramNotify} handler={handleTelegramNotifications} />
      </Plate>

      {isMobile &&
        <Plate className="dropdownmenu__link">
          {menuItems.map((item, index) => (
            <Link key={index} className="dropdownmenu__link-item" to={item.to} onClick={dropDownMenuStore.close}>
              <div className={`dropdownmenu__link-icon ${item.icon}-icon`} />
              <p>{item.text}</p>
              {item.status && <Badges status={item.status} />}
            </Link>
          ))}
        </Plate>
      }

      <Plate className="dropdownmenu__exit">
        <div className="dropdownmenu__exit-container" onClick={handleLogout}>
          <div className="dropdownmenu__exit-icon" />
          <p>Выйти из аккаунта</p>
        </div>
      </Plate>
    </div>
  );
});
