import { Link } from "react-router-dom";

import Plate from "components/UI/Plate/Plate";
import Button from "components/UI/Button/Button";
import FoldableSection from "components/UI/FoldableSection/FoldableSection";
import { HowItWorksSteps } from "./HowItWorksSteps/HowItWorksSteps";

import AppStore from "AppStore";

import { useMatchMedia } from "utils/hooks/use-match-media";
import { EVENTS, metricsEvent } from "metrics";

import "./HowItWorks.scss";

export default function HowItWorks({
  isExpanded,
  toggleExpanded,
  title,
  stepData,
  additionalContent,
  advicesText,
}) {
  const { isMobile } = useMatchMedia();
  const plateClassName = `how-it-works ${AppStore.isAuth ? "other-margin" : ""} ${isExpanded ? "other-padding" : ""}`;

  return (
    <Plate className={plateClassName}>
      <FoldableSection className="how-it-works__advices" expanded={isExpanded}>
        {!isMobile && (
          <div className="how-it-works__header">
            <p className="how-it-works__header-title">{title}</p>
            <Button
              className="how-it-works__header-button-more"
              title={isExpanded ? "Свернуть" : "Узнать"}
              onClick={toggleExpanded}
            />
          </div>
        )}

        <div>
          <div className="how-it-works__advices-container">
            <div className="how-it-works__advices-content">
              {isMobile ? (
                <>
                  <div className="how-it-works__mobile-row">
                    {stepData.slice(0, 2).map((step, index) => (
                      <HowItWorksSteps
                        key={index}
                        {...step}
                      />
                    ))}
                  </div>
                  <div className="how-it-works__mobile-single">
                    {stepData.slice(2).map((step, index) => (
                      <HowItWorksSteps
                        key={index + 2}
                        {...step}
                      />
                    ))}
                  </div>
                </>
              ) : (
                stepData.map((step, index) => (
                  <HowItWorksSteps
                    key={index}
                    {...step}
                  />
                ))
              )}
              <div className="how-it-works__additional-content">
                {additionalContent}
              </div>
            </div>
            {advicesText && <p className="how-it-works__advices-text">{advicesText}</p>}
          </div>

          <div className="how-it-works__buttons">
            <Link to={process.env.REACT_APP_SUPPORT_ADDRESS} target="_blank">
              <Button
                title="Хочу задать вопросы поддержке"
                className="how-it-works__button-support"
                onClick={() => metricsEvent(EVENTS.TELEGRAM_HELP)}
              />
            </Link>
            <Button
              title="Все понятно — приступим!"
              classes={["wide"]}
              onClick={toggleExpanded}
            />
          </div>
        </div>
      </FoldableSection>
    </Plate>
  );
}
