import isNetworkError from 'is-network-error';
import lodash from 'lodash';

import AppStore from 'AppStore';

export const ProjectStatuses = {
  NEW: 'new',
  RUNNING: 'running',
  FINISHED: 'finished',
  FAILED: 'failed',
};

export const ReportStatuses = {
  NEW: 'new',
  RUN: 'run',
  READY: 'ready',
  REPLACE: 'replace',
  REWORK: 'rework',
  APPROVED: 'approved',
  FINISHED: 'finished',
  FAILED: 'failed',
};

/**
 * @typedef ProjectActivityInfo
 * @property {String} type Тип активности. Специфичны для каждой площадки.
 * @property {Number} count Количество этой активности в проекте.
 */

/**
 * @typedef Report Отчет исполнителя.
 * @property {Number} id Идентификатор отчета.
 * @property {Number} projectId Идентификатор проекта.
 * @property {String} scheduledDatetime Назначенное время выполнения в формате ISO.
 * @property {String} status Статус отчета (см. ReportStatuses).
 * @property {String} [searchPhrase] Поисковая фраза, которая была назначена исполнителю.
 * @property {String} [brandPhrase] Брендовая фраза, которая была назначена исполнителю.
 * @property {String} fullSearchPhrase Полная поисковая фраза, которая была назначена исполнителю.
 * @property {String[]} chain Цепочка активностей, которая была назначена исполнителю.
 * @property {String[]} questions Проверочные вопросы, которые были заданы исполнителю.
 * @property {String} answer Ответ исполнителя.
 * @property {String} [sentDatetime] Время отправки задачи в пул для исполнителей.
 * @property {String} [readyDatetime] Время выполнения задачи в формате ISO.
 * @property {String} [acceptDatetime] Время подтверждения задачи в формате ISO: либо автоподтверждения, либо прокликанный пользователем аппрув.
 * @property {Number} [workerId] Идентификатор исполнителя.
 * @property {'empty'|'copy'|'foreign'} [autoReworkReason] Причина автоматической замены исполнителя.
 * @property {Number} [extraPrice] Наценка на задачу от пользователя.
 * @property {String} [extraPriceUpdateDatetime] Время последнего обновления наценки.
 * @property {boolean} [cancelling] Признак того, что отмена задачи в процессе.
 */

/**
 * @typedef Project Проект по продвижению, который выполняет бекенд.
 * @property {Number} id Идентификатор проекта (сквозной по всей системе).
 * @property {String} [title] Пользовательское имя проекта.
 * @property {String} status Статус проекта (см. ProjectStatuses).
 * @property {String} type Идентификатор площадки.
 * @property {String} link Ссылка на то, что пользователь хочет продвигать. Основной атрибут проекта.
 * @property {boolean} [screenshot] Есть ли у проекта скриншот.
 * @property {String} address Полная строка адреса, введенная пользователем.
 * @property {String} country Страна объявления.
 * @property {String} city Город объявления.
 * @property {String} category Категория объявления на площадке.
 * @property {String[]} searchPhrases Список поисковых фраз для продвижения по ним.
 * @property {String[]} brandPhrases Список брендовых фраз для продвижения по ним.
 * @property {ProjectActivityInfo[]} activities Активности в проекте.
 * @property {Number} price Стоимость проекта в рублях.
 * @property {Number} days На сколько дней рассчитано выполнение проекта.
 * @property {Number} progress Прогресс выполенения проекта. Целое число от 0 до 10.
 * @property {Object} extras Какие-то специфичные настройки проекта, переданные на этапе его создания.
 * @property {Report[]} reports Все отчеты для проекта с любым состоянием (в том числе и назначенные на будущее).
 * @property {boolean} [cancelling] Признак того, что отмена проекта в процессе.
 * @property {boolean} [api] Признак того, что проект был создан через Business API.
 */

/**
 * @typedef ProjectParams Параметры проекта.
 * @property {String} type Тип проекта: AVITO, TELEGRAM, ...
 * @property {String} link Ссылка на то, что пользователь хочет продвигать. Основной атрибут проекта.
 * @property {String} address Полная строка адреса, введенная пользователем.
 * @property {String} country Страна объявления.
 * @property {String} city Город объявления.
 * @property {String} category Категория объявления на площадке.
 * @property {String[]} searchPhrases Список поисковых фраз для продвижения по ним.
 * @property {String[]} brandPhrases Список брендовых фраз для продвижения по ним.
 * @property {ProjectActivityInfo[]} activities Активности в проекте.
 * @property {Number} price Стоимость проекта в рублях.
 * @property {Number} days На сколько дней рассчитано выполнение проекта.
 */

/**
 * @typedef ProfileData Профиль пользователя.
 * @property {number} id Идентификатор пользователя.
 * @property {number} [sponsorId] Идентификатор спонсора.
 * @property {string} login Логин пользователя.
 * @property {boolean} agree Признак соглашения с условиями и правилами сервиса.
 * @property {number} balance Баланс пользователя в рублях.
 * @property {number} bonuses Сумма бонусов, доступных к трате.
 * @property {boolean} [warning] Пользователю выставлено предупреждение, ему нужно обратиться в техподдержку.
 * @property {number} [telegramId] Идентификатор привязанного Telegram.
 * @property {boolean} [telegramNotify] Признак оповещений в Telegram.
 */

/**
 * @typedef AuthCode Код авторизации.
 * @property {string} text Код авторизации в строковом виде.
 * @property {Date} expiresAt Дата истечения кода авторизации.
 */

/**
 * @typedef BecomeReferralResult
 * @property {?boolean} [already] Пользователь уже был рефералом этого спонсора.
 * @property {string} message Информационное сообщение.
 */

/**
 * @typedef BonusesInfo
 * @property {number} available Доступные бонусы.
 * @property {number} earned Заработанные бонусы.
 * @property {number} withdrawing Бонусы в процессе вывода.
 * @property {number} withdrawn Выведенные бонусы.
 */

/**
 * @typedef BonusesReferralStats
 */

/**
 * Сам клиент может генерить только этот тип исключений.
 * Если поймали именно этот тип, то свойство message можно выводить на экран пользователю.
 */
export class ClientError extends Error { }
export class NetworkError extends ClientError { }
export class ApiError extends ClientError { }

const NETWORK_ERROR_MESSAGE = 'Ошибка сети. Проверьте подключение к Интернету.'
const UNKNOWN_ERROR_MESSAGE = 'Неизвестная ошибка. Пожалуйста, обратитесь в техподдержку.';

export class Client {

  #api;
  #getToken;
  #version;

  constructor(api, getToken, version) {
    this.#api = api;
    this.#getToken = getToken;
    this.#version = version;
  }

  async #createRequest(method, path, params, token) {
    let headers = {};
    if (token)
      headers['Authorization'] = 'Bearer ' + token;
    if (params)
      headers['Content-Type'] = 'application/json';

    let res;
    let body;

    let endpoint = this.#api + path;
    if (this.#version) {
      const url = new URL(endpoint);
      url.searchParams.append('v', this.#version);
      endpoint = url.toString();
    }

    try {
      res = await fetch(endpoint, {
        method: method,
        headers: headers,
        body: params ? JSON.stringify(params) : undefined
      });
      body = await res.json();
    } catch (e) {
      if (e instanceof SyntaxError)
        throw new NetworkError(NETWORK_ERROR_MESSAGE); // Probably 502 from NGINX
      if (isNetworkError(e))
        throw new NetworkError(NETWORK_ERROR_MESSAGE);
      throw e;
    }

    if (!res.ok)
      throw new ApiError(body.message ?? UNKNOWN_ERROR_MESSAGE);
    return body;
  }

  async #createUnauthorizedRequest(method, path, params) {
    return this.#createRequest(method, path, params);
  }

  async #createAuthorizedRequest(method, path, params) {
    return this.#createRequest(method, path, params, this.#getToken());
  }

  /**
   * Регистрация нового пользователя.
   * @param {string} login Пока что здесь может быть только email.
   * @param {string} password Пароль, сервер проверяет его на некоторые требования по безопаности.
   * @param {?number} [sponsorId] Идентификатор спонсора (тот, кто пригласил в рефку).
   * @returns {Promise<string>} Токен для последующих обращений к серверу.
   */
  async signup(login, password, sponsorId) {
    const reg = {
      login: login,
      password: password,
    };
    if (!lodash.isNil(sponsorId))
      reg.sponsorId = sponsorId;

    const res = await this.#createAuthorizedRequest('POST', '/v1/signup', reg);
    return res.jwt;
  }

  /**
   * Вход пользователя для получения токена доступа.
   * @param {String} login
   * @param {String} password
   * @returns {Promise<String>} Токен для последующих обращений к серверу.
   */
  async signin(login, password) {
    return (await this.#createUnauthorizedRequest('POST', '/v1/signin', { login: login, password: password })).jwt;
  }

  /**
   * Отправка запроса на восстановление пароля.
   * @param {String} login
   * @returns {Promise<void>}
   */
  async restore(login) {
    return this.#createUnauthorizedRequest('POST', '/v1/restore', { login: login });
  }

  /**
   * Установка нового пароля.
   * @param {String} token Токен, который был отправлен пользователю по резервному каналу (ссылка для восстановления на email).
   * @param {String} password Новый пароль.
   * @returns {Promise<void>}
   */
  async reset(token, password) {
    return this.#createUnauthorizedRequest('POST', '/v1/reset', { token: token, password: password });
  }

  /**
   * Запрос баланса ЛК.
   * @returns {Promise<Number>} Сумма баланса в рублях.
   */
  async getBalance() {
    const res = await this.#createAuthorizedRequest('GET', '/v1/balance');
    return res.balance;
  }

  /**
   * Запрос данных профиля.
   * @returns {Promise<ProfileData>}
   */
  async getProfile() {
    return this.#createAuthorizedRequest('GET', '/profile');
  }

  /**
   * Соглашение с условиями и правилами сервиса.
   * @returns {Promise<void>}
   */
  async acceptAgreements() {
    return this.#createAuthorizedRequest('POST', '/agree');
  }

  /**
   * Создание проекта.
   * @param {ProjectParams} project
   * @returns {Promise<Number>} Идентификатор созданного проекта.
   */
  async createProject(project) {
    const res = await this.#createAuthorizedRequest('POST', '/project', project);
    return res.projectId;
  }

  /**
   * Получение всех проектов пользователя.
   * @returns {Promise<Project[]>}
   */
  async getProjects() {
    return this.#createAuthorizedRequest('GET', '/projects');
  }

  /**
   * Получение проекта.
   * @param {number} id
   * @returns {Promise<Project>}
   */
  async getProject(id) {
    return this.#createAuthorizedRequest('GET', '/project/' + id);
  }

  /**
   * Получение отчета.
   * @param {number} id
   * @returns {Promise<Project>}
   */
  async getReport(id) {
    return this.#createAuthorizedRequest('GET', '/report/' + id);
  }

  /**
   * Получение скриншота проекта.
   * @returns {Promise<string|null>} Скриншот в Base64.
   */
  async getProjectScreenshot(id) {
    const res = await this.#createAuthorizedRequest('GET', '/projects/' + id + '/screenshot');
    return res.screenshot ?? null;
  }

  /**
   * Создание копии проекта.
   * @param {Number} id
   * @returns {Promise<Number>} Идентификатор созданного проекта.
   */
  async copyProject(id) {
    const res = await this.#createAuthorizedRequest('POST', '/projects/' + id + '/copy');
    return res.projectId;
  }

  /**
   * Удаление проекта.
   * @param {Number} id
   * @returns {Promise<void>}
   */
  async deleteProject(id) {
    return this.#createAuthorizedRequest('DELETE', '/projects/' + id);
  }

  /**
   * Оплата проекта.
   * @param {Number} id
   * @returns {Promise<boolean>} Хватило ли денег на балансе.
   */
  async payProject(id) {
    const res = await this.#createAuthorizedRequest('POST', '/projects/' + id + '/pay');
    return res.success;
  }

  /**
   * Останов запущенного проекта и перенос его в архив.
   * @param {Number} id 
   * @returns {Promise<Number>} Сколько денег вернулось на баланс.
   */
  async finishProject(id) {
    const res = await this.#createAuthorizedRequest('POST', '/projects/' + id + '/finish');
    return res.moneyBack;
  }

  /**
   * Создание ссылки на пополнение баланса.
   * @param {number} amount Сумма пополнения в рублях.
   * @returns {Promise<string>} Ссылка, на которую нужно перенаправить браузер.
   */
  async createDepositLink(amount) {
    return (await this.createInvoice(amount)).payoffUrl;
  }

  /**
   * @typedef {object} Invoice
   * @property {number} id Идентификатор инвойса.
   * @property {string} [payoffUrl] Ссылка, по которой нужно оплатить. Может не быть, если выставляется счет.
   */

  /**
   * Создание инвойса.
   * @param {number} amount Сумма пополнения в рублях.
   * @param {boolean} business Безнал или физик.
   * @returns {Promise<Invoice>}
   */
  async createInvoice(amount, business = false) {
    return this.#createAuthorizedRequest('POST', '/deposit', { amount: amount, business: business });
  }

  /**
   * Одобрение отчета исполнителя.
   * @param {Number} id Идентификатор отчета.
   * @returns {Promise<void>}
   */
  async approveReport(id) {
    return this.#createAuthorizedRequest('POST', `/reports/${id}/approve`);
  }

  /**
   * Отправка отчета исполнителя на доработку.
   * @param {Number} id Идентификатор отчета.
   * @param {String} comment Комментарий к отказу.
   * @returns {Promise<void>}
   */
  async rejectReport(id, comment) {
    return this.#createAuthorizedRequest('POST', `/reports/${id}/reject`, { comment: comment });
  }

  /**
   * Отказ от работы исполнителя.
   * @param {Number} id Идентификатор отчета.
   * @param {String} comment Комментарий к отказу.
   * @returns {Promise<void>}
   */
  async replaceReport(id, comment) {
    return this.#createAuthorizedRequest('POST', `/reports/${id}/replace`, { comment: comment });
  }

  /**
   *
   * @param {String} title Название продукта, на который оставляется отзыв. Максимальная длина - 200 символов.
   * @param {String} gender Пол автора отзыва. Должно быть либо "не определено", либо "мужчина", либо "женщина".
   * @param {Number} stars Количество звезд, которое пользователь поставил продукту. Должно быть значением от 1 до 5.
   * @param {String[]} emotions Эмоции, которые испытал пользователь во время использования продукта. Максимальная длина - 3 эмоции.
   * @param {String[]} traits Характеристики, присущие данному товару или услуге. Максимальная длина - 3 характеристики.
   * @param {String} attentions Основные особенности или аспекты продукта, на которые хочет обратить внимание пользователь. Максимальная длина - 150 символов.
   * @param {Boolean} simplified Флаг, указывающий, должен ли отзыв быть написан упрощенными фразами с ошибками или нет. По умолчанию False.
   * @returns {Promise<string>} Сгенерированный текст отзыва.
   */
  async generateAiReview(title, gender, stars, emotions, traits, attentions, simplified) {
    return this.#createAuthorizedRequest('POST', `/api/ai/review`, {
      title: title,
      gender: gender,
      stars: stars,
      emotions: emotions,
      traits: traits,
      attentions: attentions,
      simplified: simplified,
    });
  }

  /**
   * 
   * @param {number} id Идентификатор проекта.
   * @param {object} obj Объект с информацией о проекте.
   * @param {string} [obj.link] Новая ссылка на продвигаемый ресурс.
   * @param {string} [obj.screenshot] Новый скриншот проекта в формате Base64 of JPEG.
   * @param {string} [obj.title] Новое название проекта.
   * @returns {Promise<object>} Объект с измененными атрибутами проекта.
   */
  async updateProject(id, { link, screenshot, title } = {}) {
    return this.#createAuthorizedRequest('PUT', `/projects/${id}`, {
      link: link,
      screenshot: screenshot,
      title: title,
    });
  }

  /**
   * @typedef BalanceInfo
   * @property {number} balance
   */

  /**
   * Обновление задачи.
   * @param {number} id Идентификатор задачи.
   * @param {{ extraPrice : number }} extraPrice Наценка в целых рублях. Не может быть ниже текущей.
   * @returns {Promise<BalanceInfo>} 
   */
  async updateTask(id, { extraPrice }) {
    return this.#createAuthorizedRequest('PUT', `/reports/${id}`, { extraPrice: extraPrice });
  }

  /**
   * Отмена задачи.
   */
  async cancelTask(id) {
    return this.#createAuthorizedRequest('POST', `/reports/${id}/cancel`);
  }

  /**
   * Отмена проекта.
   */
  async cancelProject(id) {
    return this.#createAuthorizedRequest('POST', `/projects/${id}/cancel`);
  }

  /**
   * Выпуск временного кода для авторизации.
   * @returns {Promise<AuthCode>}
   */
  async issueCode() {
    const code = await this.#createAuthorizedRequest('POST', '/code');
    return {
      text: code.text,
      expiresAt: new Date(1000 * code.expiresAt),
    };
  }

  /**
   * Включить или выключить уведомления в Telegram.
   * @param {boolean} notify
   * @returns {Promise<void>}
   */
  async onOffTelegramNotifications(notify = true) {
    return this.#createAuthorizedRequest(notify ? 'POST' : 'DELETE', '/telegram-notify');
  }

  /**
   * Вывод бонусов на баланс.
   * @param {number} amount
   * @returns {Promise<BonusesInfo>}
   */
  async moveBonusesToBalance(amount) {
    return this.#createAuthorizedRequest('POST', '/move-bonuses-to-balance?amount=' + amount);
  }

  /**
   * Вывод бонусов на карту.
   * @param {number} amount
   * @returns {Promise<BonusesInfo>}
   */
  async moveBonusesToCard(amount) {
    return this.#createAuthorizedRequest('POST', '/move-bonuses-to-card?amount=' + amount);
  }

  /**
   * Получение информации по бонусам.
   * @returns {Promise<BonusesInfo>}
   */
  async getBonusesInfo() {
    return this.#createAuthorizedRequest('GET', '/bonuses/info');
  }

  /**
   * Получение статистики по рефералам.
   * @returns {Promise<BonusesReferralStats[]>}
   */
  async getBonusesStats() {
    return this.#createAuthorizedRequest('GET', '/bonuses/stats');
  }

  /**
   * Получение истории по бонусам.
   * @returns {Promise<object[]>}
   */
  async getBonusesHistory() {
    return this.#createAuthorizedRequest('GET', '/bonuses/history');
  }
}

const client = new Client(
  process.env.REACT_APP_BACKEND_ADDRESS,
  () => AppStore.token,
  '0.37.2'
);
export default client;
