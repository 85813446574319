import classNames from "classnames";

import Button from "../Button/Button";

import "./Pagination.scss";

const DIRECTIONS = {
  BACK: "back",
  NEXT: "next",
};

/**
 * @param {number} from
 * @param {number} to
 * @returns {number[]}
 */
function genSeq(from, to) {
  const seq = [];
  for (let i = from; i <= to; i++)
    seq.push(i);
  return seq;
}

/**
 * @param {number} pages
 * @param {number} curr
 * @returns {number[]}
 */
function buildPageNumbers(pages, curr) {
  if (pages <= 7)
    return genSeq(1, pages);
  if (curr <= 4)
    return [...genSeq(1, 5), 0, pages];
  if (curr <= pages - 4)
    return [1, 0, ...genSeq(curr - 1, curr + 1), 0, pages];
  return [1, 0, ...genSeq(pages - 4, pages)];
};

/**
 * @callback onPageGoto
 * @param {number} page Not an index!
 */

/**
 * @callback onPageNext
 */

/**
 * Стандартный модуль пагинации.
 * Для десктопа показывает список страниц с переключалкой между ними.
 * Для мобилки показывает только кнопку "Показать еще".
 * @param {object} obj
 * @param {boolean} obj.isMobile Включение мобильного режима показа.
 * @param {number} obj.pages Количество страниц всего.
 * @param {number} obj.curr Номер текущей страницы (не индекс!).
 * @param {onPageGoto} obj.onPageGoto Обработчик перехода
 * на заданную страницу.
 * @param {onPageNext} obj.onPageNext Обработчик добавления данных
 * со следующей страницы.
 */
const Pagination = ({
  isMobile,
  pages,
  curr,
  onPageGoto,
  onPageNext,
}) => {

  if (pages <= 1)
    return null;

  const cannotNext = curr >= pages;
  if (isMobile) {
    if (cannotNext)
      return null;

    return (
      <Button
        title="Показать еще"
        className="pagination__show-more"
        onClick={onPageNext}
      >
        Показать еще
      </Button>
    );
  }
  const cannotBack = curr <= 1;

  const createButton = (page, i) => {
    if (!page)
      return <span key={i} className="pagination__dots">..</span>;

    const isActive = page === curr;
    return <button
      key={i}
      className={classNames("pagination__number", isActive ? "active" : "")}
      onClick={() => onPageGoto(page)}
    >
      {page}
    </button>;
  };

  const buttonClass = (direction, disabled) => classNames(
    "pagination__arrow",
    "pagination__arrow-" + direction,
    disabled ? "disabled" : ""
  );

  return (
    <div className="pagination">
      <button
        type="button"
        aria-label="Назад"
        className={buttonClass(DIRECTIONS.BACK, cannotBack)}
        onClick={() => onPageGoto(curr - 1)}
        disabled={cannotBack}
      />

      <div className="pagination__numbers">
        {buildPageNumbers(pages, curr).map((page, i) => createButton(page, i))}
      </div>

      <button
        type="button"
        aria-label="Вперед"
        className={buttonClass(DIRECTIONS.NEXT, cannotNext)}
        onClick={() => onPageGoto(curr + 1)}
        disabled={cannotNext}
      />
    </div>
  );
};

export default Pagination;
