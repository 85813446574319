import "./ValueRow.scss";

const ValueRow = ({
  login,
  id,
  accountType,
  amount,
  date,
  status,
  type,
  isMobile,
}) => {

  date = date ? new Date(date) : null;
  const dateStr = date ? date.toLocaleDateString("ru-RU") + ' '
    + date.toLocaleTimeString("ru-RU") : '';

  return (
    <div className={`table-row-${type}`}>
      {type === "withdrawing" &&
        <>
          {isMobile ? (
            <div className="table-row-item__mobile">
              <div className="table-row-item__info">
                <p className="table-row-item">{`${login} (ID: ${id}, ${accountType})`}</p>
                <p className="table-row-item">{dateStr}</p>
              </div>
              <div className="table-row-item__info">
                <p className="table-row-item">{amount.toLocaleString()} &#8381;</p>
                <p className={`table-row-item`}>{status}</p>
              </div>
            </div>
          ) : (
            <>
              <p className="table-row-item">{dateStr}</p>
              <p className="table-row-item">{`${login} (ID: ${id}, ${accountType})`}</p>
              <p className="table-row-item">{amount.toLocaleString()} &#8381;</p>
              <p className={`table-row-item`}>{status}</p>
            </>
          )}
        </>
      }
      {type === "invited" &&
        <>
          {isMobile ? (
            <div className="table-row-item__mobile">
              <div className="table-row-item__info">
                <p className="table-row-item">{`${login} (ID: ${id}, ${accountType})`}</p>
                <p className="table-row-item">{dateStr}</p>
              </div>
              <p className={`table-row-item table-row-item-bonus`}>{`+ ${amount}`}</p>
            </div>
          ) : (
            <>
              <p className="table-row-item">{`${login} (ID: ${id}, ${accountType})`}</p>
              <p className="table-row-item">{dateStr}</p>
              <p className={`table-row-item table-row-item-bonus`}>{`+ ${amount}`}</p>
            </>
          )}
        </>
      }
    </div>
  );
};

export default ValueRow;
