import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import { Popup } from "components/Popup/Popup";
import CheckBox from "components/UI/CheckBox/CheckBox";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import Button from "components/UI/Button/Button";
import { TextInput } from "components/UI/TextInput/TextInput";

import popupStore from "components/Popup/PopupStore";
import referralStore, { WithdrawalMethods } from "pages/Referral/ReferralStore";

import { useMatchMedia } from "utils/hooks/use-match-media";

import coin from "assets/images/referral/coinBlack.svg";
import card from "assets/images/referral/cardBlack.svg";

import "./ReferralWithdrawalModal.scss";

export const ReferralWithdrawalModal = observer(() => {
  const { isMobile } = useMatchMedia();

  const renderWithdrawalMethodButtons = () => (
    <div className="referral-withdrawal-modal__method-selector">
      <ButtonWithIcon
        className={`referral-withdrawal-modal__method-button ${referralStore.withdrawalMethod === WithdrawalMethods.ACCOUNT ? 'active' : ''}`}
        title="На основной счет"
        icon={coin}
        ariaLabel="Вывести бонусы на основной счет"
        onClick={() => referralStore.setWithdrawalMethod(WithdrawalMethods.ACCOUNT)}
      />
      <ButtonWithIcon
        className={`referral-withdrawal-modal__method-button ${referralStore.withdrawalMethod === WithdrawalMethods.CARD ? 'active' : ''}`}
        title={<>На карту <span>(только РФ)</span></>}
        icon={card}
        ariaLabel="Вывести бонусы на карту"
        onClick={() => referralStore.setWithdrawalMethod(WithdrawalMethods.CARD)}
      />
    </div>
  );

  return (
    <Popup modalType="referral-withdrawal-modal" title={isMobile ? "Вывод средств" : <>Вывод средств <br /> с партнерской программы</>}>
      <div className="referral-withdrawal-modal__info">
        <div className="referral-withdrawal-modal__method">
          <p>Выберите способ вывода средств</p>
          {renderWithdrawalMethodButtons()}
        </div>
        <div className="referral-withdrawal-modal__balance">
          <p>Текущий баланс:</p>
          <p>{referralStore.bonusesAvailable?.toLocaleString()}</p>
        </div>
        <div className="referral-withdrawal-modal__input">
          <p className="referral-withdrawal-modal__input-title">Введите сумму перевода</p>
          <TextInput
            type="text"
            inputmode="decimal"
            placeholder={`Сумма от ${referralStore.minWithdrawalAmount} бонусов`}
            maxLength={10}
            value={referralStore.amountToWithdraw ?? ''}
            onChange={e => referralStore.setAmountToWithdraw(e.target.value)}
          />
          {!isMobile && <p className="referral-withdrawal-modal__input-text">1 бонус = 1 &#8381;; Минимальная сумма: {referralStore.minWithdrawalAmount}</p>}
          {referralStore.withdrawalMethod === WithdrawalMethods.CARD &&
            <div className="referral-withdrawal-modal__result">
              {isMobile ?
                <p className="referral-withdrawal-modal__result-sum"><span>{referralStore.amountToReceive?.toLocaleString() ?? ''} &#8381;</span><br />Будет зачислено с учетом комиссии 6%:</p>
                :
                <p className="referral-withdrawal-modal__result-sum">Будет зачислено с учетом комиссии 6%: <span>{referralStore.amountToReceive?.toLocaleString() ?? ''} &#8381;</span></p>
              }
              <p className="referral-withdrawal-modal__result-deadline">Срок получения платежа: <span>~ 7 дней</span></p>
            </div>
          }
        </div>
      </div>

      <div className="referral-withdrawal-modal__consent-container">
        {referralStore.withdrawalMethod === WithdrawalMethods.CARD &&
          <CheckBox
            title={
              <>
                Согласие на оплату в соответствии с{" "}
                <Link
                  to="/docs/offer.pdf"
                  target="_blank"
                  className="referral-withdrawal-modal__link"
                >
                  договором оферты
                </Link>
              </>
            }
            checked={referralStore.agreedWithOffer}
            onClick={() => referralStore.setAgreedWithOffer(!referralStore.agreedWithOffer)}
          />
        }
        <div className="referral-withdrawal-modal__buttons">
          <Button
            title="Отмена"
            className="referral-withdrawal-modal__button-cancel"
            onClick={() => popupStore.close()}
          />
          <Button
            title={referralStore.withdrawalMethod === WithdrawalMethods.ACCOUNT ? "Перевести на счет" : "Указать реквизиты"}
            classes={["wide"]}
            onClick={() => referralStore.doWithdrawal()}
            disabled={!referralStore.canWithdraw && !referralStore.doWithdrawalRunning}
          />
        </div>
      </div>
    </Popup>
  );
});
